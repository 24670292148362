import { CookieBanner } from '@cointracker/styleguide';
import { CookieConsent } from '@cointracker/ui';
import { useTheme } from '../../hooks/useTheme';

export const CookieConsentWrapper = () => {
  const { isDarkTheme } = useTheme();
  return <CookieConsent isDarkTheme={isDarkTheme} showManageButton />;
};

export const RebrandCookieConsentWrapper = ({
  pathname,
}: {
  pathname: string;
}) => {
  let isDarkTheme = false;
  if (pathname.includes('enterprise')) {
    isDarkTheme = true;
  }
  return <CookieBanner isDarkTheme={isDarkTheme} showManageButton />;
};
