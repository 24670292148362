import { themeStore } from '@/store';
import { Theme, addThemeClass, isClient, useHasMounted } from '@cointracker/ui';
import { useCallback, useEffect, useMemo } from 'react';
import { useStore } from 'zustand';

export const useTheme = () => {
  const { theme, setTheme } = useStore(themeStore);
  const hasMounted = useHasMounted();

  const isDarkTheme = useMemo(() => {
    if (!isClient() || !hasMounted) {
      return false;
    }
    return (
      theme === Theme.DARK ||
      (theme === Theme.SYSTEM &&
        window.matchMedia('(prefers-color-scheme: dark)').matches)
    );
  }, [theme, hasMounted]);

  const setThemeCallback = useCallback(
    (newTheme: Theme) => {
      addThemeClass(newTheme);
      setTheme(newTheme);
    },
    [setTheme],
  );

  const toggleTheme = useCallback(() => {
    setThemeCallback(theme === Theme.LIGHT ? Theme.DARK : Theme.LIGHT);
  }, [theme, setThemeCallback]);

  useEffect(() => {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    const toggleTheme = (event: any) => {
      if (event.detail.state) {
        setThemeCallback(Theme.DARK);
      } else {
        setThemeCallback(Theme.LIGHT);
      }
    };
    window.addEventListener('toggle-theme', toggleTheme);
    return () => {
      window.removeEventListener('toggle-theme', toggleTheme);
    };
  }, [setThemeCallback]);

  return {
    theme,
    toggleTheme,
    isDarkTheme,
    setTheme: setThemeCallback,
  };
};
